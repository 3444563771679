import React, { lazy, Suspense } from 'react';

import { AppPlugin, AppRootProps } from '@grafana/data';
import { LoadingPlaceholder } from '@grafana/ui';

import { CollectorInstallationInstructionsExtensionProps } from 'extensions/CollectorInstallationInstructionsExtension';
import { DeployPipelinesExtensionProps } from 'extensions/DeployPipelinesExtension/components/DeployPipelinesExtension';
import { AppJSONData } from 'feature/common/types/AppJSONData';

import { ComingSoon } from './feature/app/components/ComingSoon';
import { isCollectorAppEnabled } from './feature/common/utils/featureToggles';

/** Faro */
let initialized = false;
async function init() {
  if (initialized) {
    return;
  }
  initialized = true;
  const { initFaro } = await import('faro/initialize');

  initFaro();
}

/** App */
const LazyApp = lazy(async () => {
  await init();
  return await import('./feature/app/components/App');
});

const App = (props: AppRootProps<AppJSONData>) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyApp {...props} />
  </Suspense>
);

// /** Extensions */
const LazyCollectorInstallationInstructionsExtension = lazy(
  () => import('extensions/CollectorInstallationInstructionsExtension')
);

const LazyDeployPipelinesExtension = lazy(
  () => import('extensions/DeployPipelinesExtension/components/DeployPipelinesExtension')
);

const CollectorInstallationInstructionsExtension = (props: CollectorInstallationInstructionsExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyCollectorInstallationInstructionsExtension {...props} />
  </Suspense>
);

const DeployPipelinesExtension = (props: DeployPipelinesExtensionProps) => (
  <Suspense fallback={<LoadingPlaceholder text="" />}>
    <LazyDeployPipelinesExtension {...props} />
  </Suspense>
);

/** Plugin */
// eslint-disable-next-line import/no-unused-modules
export const plugin = new AppPlugin<AppJSONData>().setRootPage(isCollectorAppEnabled() ? App : ComingSoon);

plugin.exposeComponent({
  title: 'Collector Installation Instructions',
  description: 'UI for displaying collector installation instructions.',
  id: 'grafana-collector-app/collector-install/v1',
  component: CollectorInstallationInstructionsExtension,
});

plugin.exposeComponent({
  title: 'Deploy pipelines extension',
  description: 'Deploy pipelines extension.',
  id: 'grafana-collector-app/deploy-pipelines/v1',
  component: DeployPipelinesExtension,
});
